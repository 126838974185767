/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { isFeatureEnabled, FeatureFlag, Query } from '@superset-ui/core';
import {
  isUserWithPermissionsAndRoles,
  UndefinedUser,
  UserWithPermissionsAndRoles,
} from 'src/types/bootstrapTypes';
import { Dashboard } from 'src/types/Dashboard';
import { findPermission } from 'src/utils/findPermission';
import { Chart } from 'src/types/Chart';
import getBootstrapData from '../../utils/getBootstrapData';

// this should really be a config value,
// but is hardcoded in backend logic already, so...
const ADMIN_ROLE_NAME = getBootstrapData().common.conf.AUTH_ROLE_ADMIN;

export const isUserAdmin = (
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserWithPermissionsAndRoles(user) &&
  Object.keys(user.roles || {}).some(
    role => role.toLowerCase() === ADMIN_ROLE_NAME.toLowerCase(),
  );

export function userHasPermission(
  user: UserWithPermissionsAndRoles | UndefinedUser | null = null,
  viewName: string,
  permissionName: string,
) {
  return (
    isUserAdmin(user) ||
    (isUserWithPermissionsAndRoles(user) &&
      Object.values(user.roles || {})
        .flat()
        .some(
          permissionView =>
            permissionView[0] === permissionName &&
            permissionView[1] === viewName,
        ))
  );
}

const isUserDashboardOwner = (
  dashboard?: Dashboard,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserWithPermissionsAndRoles(user) &&
  dashboard?.owners.some(owner => owner.id === user.userId) &&
  true;

export const canUserEditDashboard = (
  dashboard?: Dashboard,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserDashboardOwner(dashboard, user) ||
  userHasPermission(user, 'Dashboard', 'can_write');

export const canUserAccessDashboardMenu = (
  dashboard?: Dashboard,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserDashboardOwner(dashboard, user) ||
  userHasPermission(user, 'Dashboards', 'menu_access');

export const canUserSaveAsDashboard = (
  dashboard: Dashboard,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserWithPermissionsAndRoles(user) &&
  findPermission('can_write', 'Dashboard', user?.roles) &&
  (!isFeatureEnabled(FeatureFlag.DASHBOARD_RBAC) ||
    isUserAdmin(user) ||
    isUserDashboardOwner(dashboard, user));

const isUserChartOwner = (
  chart?: Chart,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserWithPermissionsAndRoles(user) &&
  chart?.owners?.some(owner => owner.id === user.userId);

export const canUserEditChart = (
  chart?: Chart,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserChartOwner(chart, user) ||
  userHasPermission(user, 'Chart', 'can_write');

export const canUserAccessChartMenu = (
  chart?: Chart,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserChartOwner(chart, user) ||
  userHasPermission(user, 'Charts', 'menu_access');

const isUserQueryOwner = (
  query?: Query,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
  // @ts-ignore
) => query?.userId === user?.userId;

export const canUserEditQuery = (
  query?: Query,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserQueryOwner(query, user) ||
  userHasPermission(user, 'SavedQuery', 'can_write');

export const canUserAccessQueryMenu = (
  query?: Query,
  user?: UserWithPermissionsAndRoles | UndefinedUser | null,
) =>
  isUserQueryOwner(query, user) ||
  userHasPermission(user, 'SQL Lab', 'menu_access');
